var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"autocomplete":"off","tag":"form"}},[_c('VContainer',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('SDatatableBase',{attrs:{"disable-sort":true,"headers":_vm.headers,"hide-default-footer":true,"hide-top":true,"items":_vm.items,"classes":"s-dense-inputs-table","min-width":"725px","name-e2-e-suffix":"groupRecipientsMapping","outlined":""},scopedSlots:_vm._u([{key:"recipient",fn:function(ref){
var ref_item = ref.item;
var name = ref_item.name;
var id = ref_item.id;
var divisions = ref_item.divisions;
return [_c('div',[_c('span',{staticClass:"s-font-15",attrs:{"s-e2e":("recipientName_" + id)}},[_vm._v(_vm._s(name[_vm.$i18n.locale]))]),_c('div',{staticClass:"s-font-11 caption_color--text",attrs:{"s-e2e":("divisionsNames_" + id)}},[_vm._v(" "+_vm._s(_vm.getDivisionNames(divisions))+" ")])])]}},{key:"integrationType",fn:function(ref){
var index = ref.index;
var ref_item = ref.item;
var id = ref_item.id;
var integrationType = ref_item.integrationType;
var mappingCode = ref_item.mappingCode;
return [_c('div',{staticClass:"s-integration-type-input s-w-150"},[_c('SFieldSelect',{attrs:{"id":"integrationType","dense":true,"initial-model":_vm.initialFormData.content.recipients[index].integrationType,"items":_vm.getIntegrationTypes(),"maxHeight":600,"model":integrationType,"outlined":true,"s-e2e":("integrationType_" + id),"single-line":true,"contentClass":"s-integration-type-menu","itemText":"description","itemValue":"type","name":"integrationType","rules":"required"},on:{"update:model":function($event){return _vm.dispatchEvent({ key: 'integrationType', value: $event, index: index })}}})],1)]}},{key:"mappingCode",fn:function(ref){
var index = ref.index;
var ref_item = ref.item;
var id = ref_item.id;
var mappingCode = ref_item.mappingCode;
var integrationType = ref_item.integrationType;
return [_c('div'),(integrationType && integrationType !== 1)?_c('SMappingCode',{attrs:{"id":"mappingCode","initial-model":_vm.initialFormData.content.recipients[index].mappingCode,"model":mappingCode,"s-e2e":("mappingCode_" + id),"name":"mappingCode","rules":"required|anyFieldMax:8"},on:{"update:model":function($event){return _vm.dispatchEvent({ key: 'mappingCode', value: $event.value, index: index })}}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }